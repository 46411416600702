<app-header menu="no">
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Edit {{customAttribute.form_type}} Attribute</h1>
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">
    <div class="card card-style">
        <div class="content">
            <Form id="Form" @submit="updateAttribute" novalidate :validation-schema="formValidations" v-slot="{ errors }" autocomplete="off">
                <div class="row align-items-center justify-content-between mb-0">
                    <div class="col-5">
                        <form-control class="validate-field input-style-active" :class="{'is-invalid' : errors.country}">
                            <app-select name="country" placeholder="Country" disabled="true" :options="$store.state.selectData.countries" value-prop="id" v-model="customAttribute.country_id">
                                <template v-slot:default="slotProps">
                                    {{ slotProps.item.emoji }} &nbsp;{{ slotProps.item.name }}
                                </template>
                                <template v-slot:display="slotProps">
                                    <span class="font-600 me-2">{{ slotProps.item.emoji }}</span>
                                    {{ slotProps.item.name }}
                                </template>
                            </app-select>
                            <template v-slot:errors>{{errors.country}}</template>
                        </form-control>
                    </div>

                    <!-- Status Part -->
                    <div class="form-check icon-check mb-4 col-5">
                        <div class="col-3">
                            <label class="form-check-label font-14 font-600" :class="customAttribute.status=='enabled' ? 'color-green-dark':'color-red-dark'" for="status">Status</label>
                        </div>
                        <div class="col-3">
                            <input class="form-check-input" type="checkbox" true-value="enabled" false-value="disabled" id="status" v-model="customAttribute.status" required>
                            <i class="icon-check-1 fa fa-square color-gray-dark font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 color-highlight"></i>
                        </div>
                    </div>
                </div>

                <!-- Field Name Part -->
                <form-control class="validate-field input-style input-style-active has-borders" :class="{'is-invalid' : errors.name}">
                    <Field as="input" type="text" name="name" v-model="customAttribute.name" disabled class="form-control" id="name"></Field>
                    <label for="name" class="color-primary-dark font-13 font-600 font-13 font-600 gradient-grey-dark">Attribute Name</label>
                    <em>(required)</em>
                    <template v-slot:error>{{errors.name}}</template>
                </form-control>

                <!-- Group Selection part -->
                <div v-show="customAttribute.form_type =='registration'">
                    <form-control class="validate-field input-style input-style-active has-borders" :class="{'is-invalid' : errors.group}">
                        <Field as="input" type="text" name="group" v-model="customAttribute.group" class="form-control" id="group"></Field>
                        <label for="group" class="color-primary-dark font-13 font-600">Group</label>
                        <em>(required)</em>
                        <template v-slot:error>{{errors.group}}</template>
                    </form-control>
                </div>

                <div v-show="customAttribute.form_type=='ticket'">
                    <form-control class="validate-field input-style input-style-active has-borders" :class="{'is-invalid' : errors.ticket_type_id}">
                        <app-select name="ticket_type_id" placeholder="Ticket Type" :options="ticketTypes" value-prop="id" v-model="customAttribute.ticket_type_id">
                            <template v-slot:default="slotProps">
                                {{ slotProps.item.text }}
                            </template>
                        </app-select>
                        <template v-slot:error>{{errors.ticket_type_id}}</template>
                    </form-control>

                    <form-control class="validate-field input-style input-style-active has-borders" :class="{'is-invalid' : errors.registration_field_id}">
                        <app-select name="registration_field_id" placeholder="Registration Field" :options="registrationFields" value-prop="id" v-model="customAttribute.registration_field_id">
                            <template v-slot:default="slotProps">
                                {{ getLabel(slotProps.item.text) }} ({{slotProps.item.text}})
                            </template>
                        </app-select>
                        <template v-slot:error>{{errors.registration_field_id}}</template>
                    </form-control>
                </div>

                
                <!-- Sequence Part -->
                <form-control class="validate-field input-style input-style-active has-borders" :class="{'is-invalid' : errors.sequence}">
                    <Field as="input" type="number" name="sequence" v-model="customAttribute.sequence" class="form-control" id="sequence"></Field>
                    <label for="sequence" class="color-primary-dark font-13 font-600 font-13 font-600">Sequence</label>
                    <em>(required)</em>
                    <template v-slot:error>{{errors.sequence}}</template>
                </form-control>

                <!-- Input Type Part -->
                <form-control class="validate-field input-style input-style-active has-borders " :class="{'is-invalid' : errors.type}">
                    <app-select name="type" placeholder="Input Type" :options="inputTypes" value-prop="id" v-model="customAttribute.type">
                        <template v-slot:default="slotProps">{{slotProps.item.text}}</template>
                    </app-select>
                    <template v-slot:error>{{errors.type}}</template>
                </form-control>

                <form-control class="validate-field input-style input-style-active has-borders" :class="{'is-invalid' : errors.meta}">
                    <Field as="input" type="text" name="meta" v-model="customAttribute.meta" class="form-control" id="meta"></Field>
                    <label for="meta" class="color-primary-dark font-13 font-600">Validation (in json string)</label>
                    <em>(required)</em>
                    <template v-slot:error>{{errors.meta}}</template>
                </form-control>


                <!-- Required And Private Part -->
                <!-- <div class="row ms-3">
                    <div class="form-check icon-check">
                        <div class="col-3">
                            <label class="form-check-label font-14 font-600" :class="meta.required==true ? 'color-blue-dark':''" for="required">Required</label>
                        </div>
                        <div class="col-3">
                            <input class="form-check-input" type="checkbox" value="true" id="required" v-model="meta.required" required>
                            <i class="icon-check-1 fa fa-square color-gray-dark font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 color-highlight"></i>
                        </div>
                    </div>
                    <div class="form-check icon-check">
                        <div class="col-3">
                            <label class="form-check-label font-14 font-600" :class="meta.private==true ? 'color-blue-dark':''" for="private">Private</label>
                        </div>
                        <div class="col-3">
                            <input class="form-check-input" type="checkbox" value="true" id="private" v-model="meta.private" required>
                            <i class="icon-check-1 fa fa-square color-gray-dark font-16"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 color-highlight"></i>
                        </div>
                    </div>
                </div> -->

                <!--If Input Type Is Text For That Text Size part -->
                <!-- <div v-show="customAttribute.type=='input_text'">
                    <form-control class="validate-field input-style input-style-active has-borders" :class="{'is-invalid' : errors.size}">
                        <Field as="input" type="number" name="size" v-model="meta.size" class="form-control" id="textSize"></Field>
                        <label for="textSize" class="color-primary-dark font-13 font-600">Size</label>
                        <em>(required)</em>
                        <template v-slot:error>{{errors.size}}</template>
                    </form-control>
                </div> -->


                <!--If Input Type Is Number For That Digit part -->
                <!-- <div v-show="customAttribute.type=='input_number'">
                    <form-control class="validate-field input-style input-style-active has-borders" :class="{'is-invalid' : errors.digits}">
                        <Field as="input" type="number" name="digits" v-model="meta.digits" class="form-control" id="digits"></Field>
                        <label for="digits" class="color-primary-dark font-13 font-600">Digits</label>
                        <em>(required)</em>
                        <template v-slot:error>{{errors.digits}}</template>
                    </form-control>
                    <form-control class="validate-field input-style input-style-active has-borders" :class="{'is-invalid' : errors.digitsBetween}">
                        <Field as="input" type="text" name="digitsBetween" v-model="meta.digits_between" class="form-control" id="digits_between"></Field>
                        <label for="digits_between" class="color-primary-dark font-13 font-600">Digits Between From</label>
                        <em>(required)</em>
                        <template v-slot:error>{{errors.digitsBetween}}</template>
                    </form-control>
                </div> -->

                <!--If Input Type Is File For That File Type part -->
                <!-- <div v-show="customAttribute.type=='input_file'">
                    <form-control class="validate-field input-style input-style-active " :class="{'is-invalid' : errors.fileType}">
                        <app-select name="fileType" placeholder="File Type" multiple="true" :options="fileTypes" value-prop="value" v-model="meta.file_type">
                            <template v-slot:default="slotProps">
                                {{ slotProps.item.type }}
                            </template>
                        </app-select>
                        <template v-slot:error>{{errors.fileType}}</template>
                    </form-control>

                    <form-control class="validate-field input-style input-style-active has-borders" :class="{'is-invalid' : errors.maxSize}">
                        <Field as="input" type="number" name="maxSize" v-model="meta.max" class="form-control" id="max"></Field>
                        <label for="max" class="color-primary-dark font-13 font-600">Max Size</label>
                        <em>(required)</em>
                        <template v-slot:error>{{errors.maxSize}}</template>
                    </form-control>
                </div> -->

                <button type="submit" class="btn btn-l btn-ripple font-600 font-13 primary-light-bg letterSpacing rounded-s w-100 mt-3">Update</button>
            </Form>
        </div>
    </div>
</div>