import customAttributesService from "../../services"
export default {
    data() {
        return {
            customNewAttribute: {},
            meta: {},
            formType: [{
                    name: "Ticket",
                    value: "ticket",
                },
                {
                    name: "Registration",
                    value: "registration",
                },
            ],
            inputTypes: [],
            ticketTypes: [],
            formValidations: {
                formType: v => !!v || "Form Type is required",
                name: v => !!v || "Name is required",
                group: v => {
                    if(this.customNewAttribute.form_type == "registration") {
                        return !!v || "Group is required";
                    }
                    return true;
                },
                ticket_type_id: v => {
                    if(this.customNewAttribute.form_type == "ticket") {
                        return !!v || "Ticket Type is required";
                    }
                    return true;
                },
                sequence: v => !!v || "Sequence is required",
                country: v => !!v || "Country is required",
                type: v => !!v || "Input Type is required",
                meta: v => {
                    if (v) {
                        try {
                            JSON.parse(v);
                        } catch (e) {
                            return "Validation is invalidate json string.";
                        }
                    } else {
                        return "Validation is required";
                    }
                    return true
                },
                // size: v => !!v || "Size is required",
                // digits: v => !!v || "Digits is required",
                // digitsBetween: v => !!v || "Digits Between is required",
                // fileType: v => !!v || "File Type is required",
                // maxSize: v => !!v || "Max is required",
            },
        };
    },
    mounted() {
        this.customNewAttribute.form_type = this.$route.query.form_type;
        customAttributesService.getSelectData({
            'type': ['ticketTypes', 'inputTypes']
        }).then(response => {
            this.ticketTypes = response.data.ticketTypes;
            this.inputTypes = response.data.inputTypes;
        });
    },
    methods: {
        setNewAttribute() {
            console.log(this.customNewAttribute)
            this.showLoader(true);
            this.$popper.wait("creating...");
            customAttributesService.addCustomAttribute(this.customNewAttribute).then(response => {
                this.hideLoader();
                this.$popper.success(response.data.message)
                console.log(response.data)
                this.$router.push({
                    name: 'custom-attributes-home'
                });
            })
        }
    }
}