import customAttributesService from "../../services"
export default {
    data() {
        return {
            customAttribute: {},
            meta: {},
            ticketTypes: [],
            registrationFields: [],
            formValidations: {
                name: v => !!v || "Name is required",
                group: v => {
                    if(this.customAttribute.form_type == "registration") {
                        return !!v || "Group is required";
                    }
                    return true;
                },
                ticket_type_id: v => {
                    if(this.customAttribute.form_type == "ticket") {
                        return !!v || "Ticket Type is required";
                    }
                    return true;
                },
                sequence: v => !!v || "Sequence is required",
                country: v => !!v || "Country is required",
                type: v => !!v || "Input Type is required",
                meta: v => {
                    if (v) {
                        try {
                            JSON.parse(v);
                        } catch (e) {
                            return "Validation is invalidate json string.";
                        }
                    } else {
                        return "Validation is required";
                    }
                    return true
                },
            },
        };
    },
    mounted() {
        this.showLoader(true);
        customAttributesService.getCustomAttributeDetail(this.$route.query.id, {
            'form_type': this.$route.query.form_type
        }).then(response => {
            this.hideLoader();
            this.customAttribute = response.data.data;
            this.customAttribute.meta = JSON.stringify(this.customAttribute.meta);
            this.customAttribute.form_type = this.$route.query.form_type;

            customAttributesService.getSelectData({
                'type': ['ticketTypes', 'inputTypes', 'registrationFields'], 'country_id': this.customAttribute.country_id
            }).then(response => {
                this.ticketTypes = response.data.ticketTypes;
                this.inputTypes = response.data.inputTypes;
                this.registrationFields = response.data.registrationFields;
            })
        });

    },
    methods: {
        updateAttribute() {
            this.showLoader(true);
            this.$popper.wait("updating...");
            customAttributesService.updateCustomAttribute(this.$route.query.id, this.customAttribute).then(response => {
                this.hideLoader();
                this.$popper.success(response.data.message)
            })
        }
    }
}