import customAttributesService from "../../services";
export default {
    data() {
        return {
            searchedResultShow: false,
            country_id: (this.$route.query.country ? this.$route.query.country : this.defaultFilterCountryId()),
            search: "",
            registrationAttributes: [],
            ticketAttributes: [],
            currentFormType: "registration",
        };
    },
    watch: {
        'country_id': function () {
            this.$router.replace({ query: { country: this.country_id } });
            this.getCustomAttributeList();
        },
    },
    mounted() {
        this.getCustomAttributeList();
    },
    methods: {
        getRegistrationAttribute() {
            this.currentFormType = "registration";
            this.getCustomAttributeList();
        },
        getTicketAttribute() {
            this.currentFormType = "ticket";
            this.getCustomAttributeList();
        },
        getCustomAttributeList() {
            this.showLoader(true);
            customAttributesService.getCustomAttributeList({
                'country_id': this.country_id,
                'form_type': this.currentFormType,
                'search': this.search,
            }).then(response => {
                this.hideLoader();
                this.ticketAttributes = [];
                this.registrationAttributes = [];
                if (this.currentFormType == 'ticket') {
                    this.ticketAttributes.push(response.data.data);
                    this.ticketAttributes = this.ticketAttributes[ 0 ];
                }
                if (this.currentFormType == 'registration') {
                    this.registrationAttributes.push(response.data.data);
                    this.registrationAttributes = this.registrationAttributes[ 0 ];
                }
            })
        },
        filterUpdate() {
            this.getCustomAttributeList();
            this.searchedResultShow = true;
        },
        filterClear() {
            this.search = "";
            this.getCustomAttributeList();
            this.searchedResultShow = false;
        }
    }
}