import api from "@/common/services/api";

export default new class {
    baseUrl = "";

    getSelectData(data) {
        return api.instance.get("/get-select-data", {
            params: data
        });
    }
    getCustomAttributeList(data) {
        return api.instance.get("/attributes", {
            params: data
        });
    }

    getCustomAttributeDetail(id, data) {
        return api.instance.get("/attributes/" + id, {
            params: data
        });
    }
    updateCustomAttribute(id, data) {
        return api.instance.put("/attributes/" + id, data);
    }
    addCustomAttribute(data) {
        return api.instance.post("/attributes", data);
    }
};