<app-header menu="no">
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">{{formType}} Attribute Details</h1>
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>
    <router-link
        :to="{name: 'custom-attributes-edit',query: {'id':$route.query.id,'form_type' : $route.query.form_type}}"
        class="header-icon header-icon-2">
        <i class="far fa-edit"></i>
    </router-link>
</app-header>
<div class="page-title-clear"></div>

<div class="page-content">
    <div class="card card-style bg-theme pb-0 mt-2">
        <div class="content" id="tab-group-1">

            <div v-if="attributeDetail.name">
                <label class="text-dark letterSpacing font-13 font-600 ms-2">
                    Attribute Label
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-500 mb-2 text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                    {{ getLabel(attributeDetail.name) }}
                </div>
            </div>
            <div>
                <label class="text-dark letterSpacing font-13 font-600 ms-2">
                    Name
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-500 mb-2  text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                    {{ attributeDetail.name ? attributeDetail.name : '-' }}
                </div>
            </div>
            <div v-if="attributeDetail.status">
                <label class="text-dark letterSpacing font-13 font-600 ms-2 ">
                    Status
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-500 mb-2 text-capitalize text-secondary"
                    style="border:none; border-radius: 30px !important;"
                    :style="[attributeDetail.status == 'enabled' ? {'background-color': 'rgb(189, 247, 212)'} : {'background-color': 'rgb(252, 220, 220)'}]">
                    {{ attributeDetail.status }}
                </div>
            </div>
            <div v-if="attributeDetail.group">
                <label class="text-dark letterSpacing font-13 font-600 ms-2 ">
                    Group
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-500 mb-2  text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                    {{ attributeDetail.group }}
                </div>
            </div>
            <div v-if="attributeDetail.ticket_type_id">
                <label class="text-dark letterSpacing font-13 font-600 ms-2 ">
                    Ticket Type
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-500 mb-2  text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                    {{ attributeDetail.ticket_type_name }}
                </div>
            </div>
            <div>
                <label class="text-dark letterSpacing font-13 font-600 ms-2 ">
                    Input Type
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-500 mb-2  text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                    {{ attributeDetail.type ? attributeDetail.type : '-' }}
                </div>
            </div>

            <div>
                <label class="text-dark letterSpacing font-13 font-600 ms-2 ">
                    Sequence
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-600 mb-2 text-capitalize text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                    {{ attributeDetail.sequence ? attributeDetail.sequence : '-' }}
                </div>
            </div>
            <div>
                <label class="text-dark letterSpacing font-13 font-600 ms-2 text-capitalize ">
                    Registration field link
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-600 mb-2 text-capitalize text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                    {{ attributeDetail.registration_field_name ? attributeDetail.registration_field_name : '-' }}
                </div>
            </div>
        </div>
    </div>

    <div class="custAttr-cardTitleContainer mb-3">
        <div class="custAttr-validationIconWrapper ms-2">
            <div class="custAttr-IconContainer">
                <i class="fas fa-clipboard-check custAttr-validationCardIcon"></i>
            </div>
        </div>
        <h5 class="custAttr-cardTitle mb-0">
            Validations
        </h5>
    </div>
    <div class="card card-style bg-theme pb-0 mt-2">
        <div class="content" id="tab-group-1">

            <div v-for="(validation,key) in attributeDetail.meta">
                <label class="text-dark letterSpacing font-13 font-600 ms-2 text-capitalize" v-if="validation != ''">
                    {{key}}
                </label>
                <div v-if="validation == true && validation != ''"
                    class="letterSpacing py-2 form-control fs-6 font-500 mb-2 text-capitalize text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(189, 247, 212)">
                    {{validation}}
                </div>
                <div v-if="validation != true && validation != false && validation != ''"
                    class="letterSpacing py-2 form-control fs-6 font-500 mb-2 text-capitalize text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                    {{validation}}
                </div>
                <div v-if="validation == false && validation != ''"
                    class="letterSpacing py-2 form-control fs-6 font-500 mb-2 text-capitalize text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(252, 220, 220)">
                    {{validation}}
                </div>
            </div>
        </div>
    </div>
</div>