import customAttributesService from "../../services"
export default {
    data() {
        return {
            attributeDetail: {},
            formType: "",
        };
    },
    mounted() {
        this.formType = this.$route.query.form_type;
        this.showLoader(true);
        customAttributesService.getCustomAttributeDetail(this.$route.query.id, {
            'form_type': this.formType
        }).then(response => {
            this.hideLoader();
            this.attributeDetail = response.data.data;
        })
    }
}