<app-header>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">custom attributes</h1>
    <a href="#" data-menu="searchMenu" class="header-icon header-icon-3">
        <i class="fa fa-search"></i>
    </a>
    <div class="header-icon header-icon-4 select-filter" style="width: 50px !important;">
        <app-select class="border-no custom-dropdown" name="country_id" placeholder=""
            :options="$store.state.selectData.countries" value-prop="id" v-model="country_id">
            <template v-slot:default="slotProps">
                {{ slotProps.item.emoji }}
            </template>
            <template v-slot:display="slotProps">
                <span class="font-600 me-2">{{ slotProps.item.emoji }}</span>
                {{ slotProps.item.name }}
            </template>
        </app-select>
    </div>
    <router-link :to="{name: 'custom-attributes-create',query:{'form_type':currentFormType}}"
        class="header-icon header-icon-2" v-if="hasPermission('custom_attribute_create')">
        <i class="fa fa-plus"></i>
    </router-link>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">
    <div class="text-center" v-if="searchedResultShow">
        <p class="font-15 font-200 my-2 letterSpacing text-secondary text-center text-capitalize">
            Search results for
            <span class="font-500 text-dark">'{{ search }}'</span>
            <button @click="filterClear" class="btn gradient-red btn-xs letterSpacing font-500 ms-3"
                style="padding: 3px 10px !important;">Clear</button>
        </p>
    </div>
    <div class="mt-2 mb-0" id="custom-attributes">
        <div class="tab-controls card-style mb-4 tabs-small tabs-rounded letterSpacing"
            data-highlight="bg-primary-light-color">
            <a href="javascript:void(0)" class="no-effect btn-ripple" data-active data-bs-toggle="collapse"
                data-bs-target="#registration" @click="getRegistrationAttribute">Registration</a>
            <a href="javascript:void(0)" class="no-effect btn-ripple" data-bs-toggle="collapse" data-bs-target="#ticket"
                @click="getTicketAttribute">Ticket</a>
        </div>
        <!-- Registration Custom Attribute List -->
        <div data-bs-parent="#custom-attributes" id="registration">
            <div v-for="(record, index) in registrationAttributes" class="card card-style rounded-s mb-2">
                <div class="content mb-1 mt-1">
                    <router-link
                        :to="{path: '/custom-attributes-detail', query: {'id':record.id , 'form_type':'registration'}}">
                        <div class="d-flex">
                            <div class="align-self-center">
                                <p class="primary-alt-base-text letterSpacing font-500 font-16 line-height-s mt-1 mb-1">
                                    {{ record.sequence }}-{{ record.name }}
                                </p>
                                <p class="letterSpacing font-12 line-height-s mt-2 mb-1 text-secondary">
                                    {{ record.group }}
                                </p>
                            </div>
                            <div class="ms-auto ps-3 align-items-end">
                                <span class="text-capitalize font-600"
                                    :class="record.status == 'enabled' ? 'text-success' : 'text-danger' ">{{
                                    record.status ? record.status : "-" }}</span>
                                <p class="font-10 mb-n2 text-end">{{ localDate(record.Created_Time) }}</p>
                            </div>
                            <div class="d-flex ps-2 align-items-center">
                                <i class="fa fa-chevron-right font-10 text-secondary"></i>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <!-- Ticket Custom Attribute List -->
        <div data-bs-parent="#custom-attributes" class="collapse" id="ticket">
            <div v-for="(record, index) in ticketAttributes" class="card card-style rounded-s mb-2">
                <div class="content mb-1 mt-1">
                    <router-link
                        :to="{path: '/custom-attributes-detail', query: {'id':record.id , 'form_type':'ticket'}}">
                        <div class="d-flex">
                            <div class="align-self-center">
                                <p class="primary-alt-base-text letterSpacing font-500 font-16 line-height-s mt-1 mb-1">
                                    {{ record.sequence }}-{{ record.name }}
                                </p>
                                <p class="letterSpacing font-12 line-height-s mt-2 mb-1 text-secondary">
                                    {{ record.ticket_type_name }}
                                </p>
                            </div>
                            <div class="ms-auto ps-3 align-items-end">
                                <span class="text-capitalize font-600"
                                    :class="record.status == 'enabled' ? 'text-success' : 'text-danger' ">{{
                                    record.status ? record.status : "-" }}</span>
                                <p class="font-10 mb-n2 text-end">{{ localDate(record.Created_Time) }}</p>
                            </div>
                            <div class="d-flex ps-2 align-items-center">
                                <i class="fa fa-chevron-right font-10 text-secondary"></i>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-5 text-center" v-if="!(ticketAttributes.length > 0 || registrationAttributes.length > 0)">
        <i class="fas fa-search font-20 text-secondary mb-3"></i>
        <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
            No Custom Attributes Found
        </p>
    </div>
</div>
<div id="searchMenu" class="menu menu-box-bottom rounded-m" data-menu-height="300" data-menu-effect="menu-over">
    <div class="menu-title p-3 pb-2">
        <h2 class="letterSpacing font-17 font-200">Search By</h2>
        <a href="#" class="close-menu" ref="closeBtn"><i class="fa fa-times-circle"></i></a>
    </div>
    <div class="card-style shadow-0 rounded-0 mb-0">
        <div class="input-style has-borders">
            <input class="form-control font-15 font-400 letterSpacing" type="text" placeholder="Search " v-model="search">
            <em></em>
            <i class="disabled invalid"></i>
            <i class="disabled valid"></i>
        </div>
    </div>
    <div class="content mt-0 d-flex justify-content-center">
        <button @click="filterUpdate" :disabled="!search"
            class="close-menu btn btn-l btn-ripple me-1 primary-light-bg rounded-s letterSpacing">
            Search
        </button>
        <button @click="filterClear" :disabled="!search"
            class="close-menu btn btn-l btn-ripple primary-alt-light-bg rounded-s letterSpacing">
            Clear
        </button>
    </div>
</div>